// Generated by Framer (6d96884)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/nFAy8p4fOASsyhPbo192/Ticker.js";
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["Xc9a3qZpq"];

const variantClassNames = {Xc9a3qZpq: "framer-v-h2pc8u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; pixelColor?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Xc9a3qZpq", pixelColor: mg2lfG70z = "var(--token-eada6075-df84-4c9f-9fba-107a6537bba4, rgba(22, 22, 24, 0.75)) /* {\"name\":\"Paragraph 75% - Theme\"} */", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Xc9a3qZpq", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-w2Idz", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-h2pc8u", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Xc9a3qZpq"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-1b0fndg-container"} layoutDependency={layoutDependency} layoutId={"BZmW2ddPw-container"} transition={transition}><Ticker alignment={"center"} direction={"left"} fadeOptions={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeWidth: 25, overflow: false}} gap={4} height={"100%"} hoverFactor={1} id={"BZmW2ddPw"} layoutId={"BZmW2ddPw"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} sizingOptions={{heightType: true, widthType: true}} slots={[<motion.div className={"framer-lbllxo"} data-framer-name={"pixel"} layoutDependency={layoutDependency} layoutId={"rbYTeBLlh"} style={{backgroundColor: mg2lfG70z}} transition={transition}/>]} speed={5} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-w2Idz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-w2Idz .framer-gjhrg8 { display: block; }", ".framer-w2Idz .framer-h2pc8u { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: 1px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 134px; }", ".framer-w2Idz .framer-1b0fndg-container { flex: 1 0 0px; height: 1px; position: relative; width: 1px; }", ".framer-w2Idz .framer-lbllxo { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 1px); overflow: hidden; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-w2Idz .framer-h2pc8u { gap: 0px; } .framer-w2Idz .framer-h2pc8u > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-w2Idz .framer-h2pc8u > :first-child { margin-left: 0px; } .framer-w2Idz .framer-h2pc8u > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1
 * @framerIntrinsicWidth 134
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"mg2lfG70z":"pixelColor"}
 */
const Framersc8cTP9By: React.ComponentType<Props> = withCSS(Component, css, "framer-w2Idz") as typeof Component;
export default Framersc8cTP9By;

Framersc8cTP9By.displayName = "separator";

Framersc8cTP9By.defaultProps = {height: 1, width: 134};

addPropertyControls(Framersc8cTP9By, {mg2lfG70z: {defaultValue: "var(--token-eada6075-df84-4c9f-9fba-107a6537bba4, rgba(22, 22, 24, 0.75)) /* {\"name\":\"Paragraph 75% - Theme\"} */", title: "pixel color", type: ControlType.Color}} as any)

addFonts(Framersc8cTP9By, [...TickerFonts])